import React from "react";
import withHydrationOnDemand from "react-hydration-on-demand";
import { TemporaryDescendant, serializeHTML } from "../../helpers/htmlHelpers";
import StyledRichText from "../../settingsPatterns/elements/StyledRichText/StyledRichText";
import { IBoxTextProps } from "./BoxText.types";
import { Memo } from "@ecp-boxes/helpers/memoWrapper";
import { StyledBoxText } from "./StyledText.methods";
import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";

const BoxText: React.FC<IBoxTextProps> = (props) => {
  const {
    messages,
    boxData: { displaySettings, contentSettings },
  } = useBoxContext<IBoxTextProps>();

  const combinedSettings = { ...contentSettings, ...displaySettings };

  //Here we used Descendant typing but it caused typing errors so temporarly we added TemporaryDescendant type.
  const descendantValue: TemporaryDescendant[] = JSON.parse(
    messages.box_text_content_text
  );

  return (
    <StyledBoxText
      $settings={combinedSettings}
      className="text-container text-custom-style"
      data-testid="text-container"
    >
      <StyledRichText
        $settings={combinedSettings}
        className="text-container__rich-text"
      >
        {descendantValue.map((el, idx) =>
          serializeHTML(el, idx, combinedSettings.link)
        )}
      </StyledRichText>
    </StyledBoxText>
  );
};

export default Memo(withHydrationOnDemand({ on: ["visible"] })(BoxText));
