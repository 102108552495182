import styled, { css } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { IBoxTextSettings } from "./BoxText.types";
import composeCss from "../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";

const generateBoxTextStyles = ({
  $settings,
  theme,
}: {
  $settings: IBoxTextSettings["$settings"];
  theme: IThemeState;
}) => {
  if (!$settings) return;
  return css`
    color: ${convertColorIdToHex($settings.text.color, theme.colorPalette)};
    ${composeCss.font($settings.font, theme.typography)}
    padding: ${$settings.padding.top}px ${$settings.padding.right}px ${$settings
      .padding.bottom}px ${$settings.padding.left}px;
  `;
};

export const StyledBoxText = styled.div<IBoxTextSettings>((props) =>
  generateBoxTextStyles(props)
);
